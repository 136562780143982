<template>
  <label
    class="relative block"
    :for="id"
    :class="[
      ...props.containerClass,
      {
        'grid md:grid-cols-[25%_1fr]':
          props.labelVisible && props.showErrorMessage,
      },
    ]"
    data-el="ui-input"
    @click="handleLabelClick"
  >
    <slot
      name="label"
      v-bind="{ label, id }"
    >
      <span
        class="inline-block"
        :class="{
          'sr-only': !props.labelVisible,
          '!mb-2 text-[0.75rem] text-blue-50 md:mb-0':
            props.variant === 'address',
        }"
      >
        {{ label }}
      </span>
    </slot>

    <slot
      name="back-button"
      v-bind="{ icon: props.backButtonIconName ?? null }"
    >
      <button
        v-if="props.showBackButton && props.backButtonIconName"
        class="absolute left-2 top-1/2 z-10 flex -translate-y-1/2 items-center justify-center p-3"
        @click="$emit('click:back', $event as PointerEvent)"
      >
        <UiIcon
          :name="props.backButtonIconName"
          class="text-blue"
          :width="14"
          :height="14"
        />
      </button>
    </slot>

    <div class="inline">
      <div
        class="relative"
        :class="[{ 'opacity-30 grayscale': props.disabled }]"
      >
        <input
          :id="id"
          :key="key"
          ref="inputElement"
          v-bind="$attrs"
          v-maska
          :data-maska="dataMaska"
          class="w-full outline-0 !ring-white disabled:cursor-not-allowed"
          :class="[
            variant !== 'blank' && inputClass.class,
            {
              '!border-errors ': !!errors[0],
              '!pl-12': !!props.showBackButton,
            },
            $attrs.class,
          ]"
          :aria-label="label"
          :disabled="disabled"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @keydown="handleKeydown"
        >
      </div>

      <slot name="insideInputContainer" />

      <UiFieldMsg
        v-if="props.showErrorMessage"
        :errors="errors"
      />
    </div>

    <slot
      name="icon"
      v-bind="{ icon: props.iconName ?? null }"
    >
      <UiIcon
        v-if="iconName"
        :name="iconName"
        class="absolute right-7 top-1/2 -translate-y-1/2 text-secondary"
        :width="16"
        :height="16"
      />
    </slot>
  </label>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, shallowRef, watch } from 'vue'
import { vMaska } from 'maska'
import UiIcon from '../../UiIcon/UiIcon.vue'
import UiFieldMsg from '../UiFieldMsg/UiFieldMsg.vue'
import type { UiInputProps } from './UiInput.types'

const props = withDefaults(defineProps<UiInputProps>(), {
  iconName: '',
  backButtonIconName: 'arrow-left',
  showBackButton: false,
  id: '',
  label: '',
  disabled: false,
  variant: '',
  labelVisible: false,
  containerClass: () => [],
  rules: '',
  showErrorMessage: false,
  errors: () => [],
  dataMaska: null,
})

const emit = defineEmits<{
  (e: 'update:model-value', value: string | number): void
  (e: 'click:label', value: PointerEvent): void
  (e: 'click:back', value: PointerEvent): void
  (e: 'change', value: string | number): void
  (e: 'input', value: string | number): void
  (e: 'focus', value: FocusEvent): void
  (e: 'blur', value: FocusEvent): void
  (e: 'keydown', value: KeyboardEvent): void
}>()

const inputElement = shallowRef<HTMLInputElement>()
const focused = ref(false)
const inputClass = computed(() => {
  switch (props.variant) {
    case 'small':
      return {
        class:
          'text-base rounded-3xl border border-blue-100 py-3 pl-7 pr-12 font-normal text-blue-50 opacity-90 placeholder:text-blue-50',
      }
    case 'search':
      return {
        class:
          'text-base rounded-full border border-blue-100 py-[0.813rem] pl-7 pr-12 font-normal text-blue-50 opacity-90 placeholder:text-blue-50',
      }
    case 'address':
      return {
        class:
          'block md:inline-block text-sm px-5 py-1.5 rounded-3xl border border-blue-100 font-normal text-blue-50 opacity-90 placeholder:text-blue-50',
      }
    case 'checkoutCode': {
      return {
        class:
        'block md:inline-block text-sm px-5 py-1.5 rounded-3xl border border-blue-100 bg-transparent font-medium text-primary placeholder:text-grey place placeholder:font-normal',
      }
    }
    default:
      return {
        class:
          'text-sm px-5 py-3 h-10 rounded-3xl border border-blue-100 font-normal text-blue-50 opacity-90 placeholder:text-blue-50',
      }
  }
})

const key = ref(0)

onMounted(() => {
  setNativeInputValue()
})

const nativeInputValue = computed(() =>
  props.modelValue == null ? '' : String(props.modelValue),
)

watch(nativeInputValue, () => setNativeInputValue())

function setNativeInputValue() {
  const input = inputElement.value
  if (!input || input.value === nativeInputValue.value) {
    return
  }

  input.value = nativeInputValue.value
}

async function handleInput(event: Event) {
  const { value } = event.target as HTMLInputElement

  emit('update:model-value', value)
  emit('input', value)
  await nextTick()
  setNativeInputValue()
}

function handleChange(event: Event) {
  emit('change', (event.target as HTMLInputElement).value)
}

function handleLabelClick(event: PointerEvent) {
  emit('click:label', event)
}

function handleFocus(event: FocusEvent) {
  focused.value = true
  emit('focus', event)
}

async function focus() {
  await nextTick()
  inputElement.value?.focus()
}

function handleBlur(event: FocusEvent) {
  focused.value = false
  emit('blur', event)
}

function blur() {
  inputElement.value?.blur()
}

function handleKeydown(event: KeyboardEvent) {
  emit('keydown', event)
}

defineExpose({
  inputElement,
  focus,
  blur,
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<style scoped lang="postcss">
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
